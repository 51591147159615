<template>
  <div class="unavailable">
    <span
      >Приложение недоступно, потому что контент от операторов не был загружен.
      😓</span
    >
  </div>
</template>

<style>
.unavailable {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 20px;
  height: 100vh;
  font-size: 20px;
  background: #333;
  color: #f0f0f0;
}
</style>
